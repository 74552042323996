import { REQ } from "constants/api/requests"
import { IResponse } from "constants/interfaces"
import useApi from "util/api"

import { GetTransactionsTableResponse, ITransactionService, TransactionActionResponse } from "./type"

class TransactionService implements ITransactionService {
  public async getTransactionsTable(
    page: number,
    pageSize: number,
    id: number | undefined,
    { type, status, coin, createdAt }: { type: string; status: string; coin?: string; createdAt?: (Date | null)[] },
    sortRow?: string,
    sortOrder?: string
  ): Promise<GetTransactionsTableResponse | void> {
    const params = {
      page,
      pageSize,
      type: type || null,
      status: status || null,
      createdAt: createdAt || null,
      sortRow,
      sortOrder,
      ...(coin && { coin })
    }
    const { data } = await useApi.get<IResponse<GetTransactionsTableResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.GET, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async confirmTransaction(id: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.CONFIRM, { id })
    if (data.success) {
      return data?.response?.success
    }
  }

  public async declineTransaction(id: string, reason?: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.DECLINE, { id, reason })

    if (data.success) {
      return data?.response?.success
    }
  }

  public async approveBankTransfer(id: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.BANK_TRANSFER.APPROVE, { id, status })
    if (data.success) {
      return data?.response?.success
    }
  }

  public async approvePayadmitTransaction(id: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.PAYADMIT.APPROVE, { id, status })
    if (data.success) return data?.response?.success
  }

  public async declinePayadmitTransaction(id: string, reason: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.PAYADMIT.DECLINE, { id, status, reason })
    if (data.success) return data?.response?.success
  }

  public async approveCryptoTransaction(id: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.CRYPTO.APPROVE, { id, status })
    if (data.success) return data?.response?.success
  }

  public async declineCryptoTransaction(id: string, reason: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.CRYPTO.DECLINE, { id, status, reason })
    if (data.success) return data?.response?.success
  }

  public async approveSubmittedCryptoTransaction(id: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.CRYPTO.SUBMITTED.APPROVE, { id, status })
    if (data.success) return data?.response?.success
  }

  public async declineSubmittedCryptoTransaction(id: string, reason: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.CRYPTO.SUBMITTED.DECLINE, {
      id,
      status,
      reason
    })
    if (data.success) return data?.response?.success
  }

  public async approveSubmittedPayadmitTransaction(id: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.PAYADMIT.SUBMITTED.APPROVE, { id, status })
    if (data.success) return data?.response?.success
  }

  public async declineSubmittedPayadmitTransaction(id: string, reason: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.PAYADMIT.SUBMITTED.DECLINE, {
      id,
      status,
      reason
    })
    if (data.success) return data?.response?.success
  }

  public async pendingWithdrawalsCount(): Promise<{ count: number } | void> {
    const { data } = await useApi.get<IResponse<{ count: number }>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.PENDING_WITHDRAWALS_COUNT)
    if (data?.success) return data?.response
  }
}

export default new TransactionService()
