import TableColumnsFilter from "components/dropdowns/TableColumnsFilter"
import { TableAction, TableHandler } from "constants/type"
import { get } from "lodash"
import { FC, useEffect, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import { useTranslation } from "react-i18next"
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti"
import { Link } from "react-router-dom"
import { GameSessions } from "services/user/type"

import { tablePageSize } from "../../constants/data"
import { SortOrderBy, SortOrderType, TableDataType } from "../../constants/eums"
import { TableColumnsInterface } from "../../constants/interfaces"
import { formatDateTime, formatTableCell } from "../../util/formatter"
import TableDropdown from "../dropdowns/TableDropdown"
import NoData from "../NoData"
import TablePagination from "../paginations/TablePagination"
import { FaCalendarAlt } from "react-icons/fa"
import MultiDatesPicker from "components/date/MultiDatesPicker"

export interface ITableProps {
  title: string
  handler: TableHandler
  columns: TableColumnsInterface[]
  onRowClick?: any
  id?: string | number
  filters?: any
  actions?: TableAction[]
  updatedAt?: any
  buttonHander?: any
  buttonText?: string
  defaultSortOrderBy?: SortOrderBy
  defaultSortOrder?: SortOrderType
  isExport?: boolean
  isColumnsFilterable?: boolean
  gameSessions?: GameSessions[]
  onDatePickerClick?: any
  dateData?: any
  handleDateChange?: (rowId: string, dates: string[]) => void
}

const Table: FC<ITableProps> = (props) => {
  const { t } = useTranslation(["landing"], { useSuspense: false })
  const {
    title,
    handler,
    columns,
    onRowClick,
    id,
    filters,
    actions,
    updatedAt,
    buttonHander,
    buttonText,
    defaultSortOrderBy = SortOrderBy.CREATED_AT,
    defaultSortOrder = SortOrderType.DESC,
    isExport = false,
    isColumnsFilterable = false,
    gameSessions,
    onDatePickerClick,
    dateData,
    handleDateChange
  } = props

  const csvLinkEl = useRef<any>(null)

  const [rows, setRows] = useState<any>([])
  const [total, setTotal] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalPages, setTotalPages] = useState<number>(1)

  const [orderBy, setOrderBy] = useState<string>(defaultSortOrderBy)
  const [order, setOrder] = useState<string>(defaultSortOrder)
  const [csvData, setCsvData] = useState<any>([])
  const [loadingCsvData, setLoadingCsvData] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeDatePicker, setActiveDatePicker] = useState<string | null>(null)
  const [selectedColumns, setSelectColumns] = useState<TableColumnsInterface[]>(
    columns.map((column) => ({
      ...column,
      selected: true
    }))
  )

  useEffect(() => {
    onLoad(1, pageSize)
  }, [pageSize, orderBy, order])

  useEffect(() => {
    onLoad(page, pageSize)
  }, [updatedAt, filters])

  const onLoad = async (page: number, pageSize: number): Promise<void> => {
    try {
      setLoading(true)
      const result = await handler(page, pageSize, id, filters, orderBy, order)
      if ((result?.data ?? []).length > 0) {
        setRows(result?.data)
        setPage(result?.page)
        setTotal(result?.total)
        setTotalPages(result?.total_pages)
        setLoading(false)
        console.log(result)
        return
      }
      setRows([])
      setPage(1)
      setTotal(1)
      setTotalPages(1)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onJump = (jumpTo: number) => {
    if (!jumpTo) return
    onLoad(jumpTo, pageSize)
  }

  const onPageSizeChange = (value: number): void => {
    setPageSize(value)
  }

  const onAction = (e: any, row: any, action: any): void => {
    e.stopPropagation()
    action(row)
  }

  const handleRequestSort = (value: string): void => {
    if (value === orderBy) {
      return setOrder(order === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC)
    }
    setOrderBy(value)
    setOrder(SortOrderType.ASC)
  }

  const handleExportAll = () => {
    if (!loadingCsvData) {
      setLoadingCsvData(true)
      handler(1, total, id, filters, orderBy, order)
        .then((result: any) => {
          const csvResult = result.data.map((row: any) =>
            selectedColumns
              .filter((column) => column.selected === true)
              .reduce((result, column) => {
                result[column.title] = formatTableCell(get(row, column.value), column.format)
                return result
              }, {} as any)
          )
          setCsvData(csvResult)
          setTimeout(() => csvLinkEl.current.link.click())
          setLoadingCsvData(false)
        })
        .catch(() => {
          setLoadingCsvData(false)
          setCsvData([])
        })
    }
  }

  const formatTableGameSessions = (rawValue?: any, currency?: string) => {
    if (!Array.isArray(rawValue) || !rawValue.length || rawValue.some((item: any) => !item || !item.currency)) {
      return <ul></ul>
    }
    if (currency) {
      const gameSessionForCurrency = rawValue?.find((item: any) => item.currency === currency)
      return <ul>{gameSessionForCurrency?.short_id}</ul>
    }
    return <ul>{rawValue && rawValue.map((item: any, index: number) => <li key={index}>{`${item.currency} - ${item.short_id}`}</li>)}</ul>
  }

  return (
    <div className="grid w-full grid-cols-1 gap-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start text-lg text-black dark:text-white gap-4">
          {title}
          {loading ? <span className="loading loading-spinner loading-md text-gray-400" /> : null}
          {buttonHander ? (
            <button className="btn btn-primary btn-sm ml-8" onClick={() => buttonHander()}>
              {buttonText}
            </button>
          ) : null}
        </div>
        {rows?.length ? (
          <div className="flex items-center gap-4">
            {isExport && (
              <>
                <button className="btn btn-success btn-link px-0" onClick={handleExportAll} disabled={loadingCsvData}>
                  {loadingCsvData ? t("loading_csv") : t("export_csv")}
                </button>
                <CSVLink data={csvData} ref={csvLinkEl} />
              </>
            )}
            {isColumnsFilterable && <TableColumnsFilter columns={selectedColumns} setColumns={setSelectColumns} />}
            <div className="flex items-center gap-2">
              <label>Rows per page:</label>
              <TableDropdown value={pageSize} onValueChange={onPageSizeChange} columns={tablePageSize} />
            </div>
          </div>
        ) : null}
      </div>
      {rows?.length ? (
        <div>
          <div className="overflow-x-auto scroll-hidden">
            <table className="z-0 table w-full table-compact no-pin">
              <thead className="bg-[#1e2530]">
                <tr className="text-[#a2adbb] text-14">
                  {selectedColumns
                    .filter((column) => column.selected === true)
                    .map((column: TableColumnsInterface, key: number) => (
                      <th key={key}>
                        <button
                          disabled={!column.sorting}
                          className={`${column.sorting && "cursor-pointer"} flex justify-start items-center gap-1 uppercase`}
                          onClick={() => handleRequestSort(column.value)}
                        >
                          {orderBy === column.value && (order === SortOrderType.ASC ? <TiArrowSortedUp /> : <TiArrowSortedDown />)}
                          {column.title}
                        </button>
                      </th>
                    ))}
                  {actions && actions.length > 0 && <th>{t("actions")}</th>}
                </tr>
              </thead>
              <tbody>
                {(rows || []).map((row: any, key: number) => {
                  const currency = row?.coin

                  return (
                    <tr key={key} onClick={() => onRowClick && onRowClick(row)} className={onRowClick && `cursor-pointer`}>
                      {selectedColumns
                        .filter((column) => column.selected === true)
                        .map((column: TableColumnsInterface, index: number) => {
                          let rawValue = get(row, column.value)
                          if (column.value === "other.paymentMethod") {
                            const method = get(row, column.value)
                            const coin = get(row, "coin")
                            rawValue = method || coin
                          }

                          return (
                            <td key={index}>
                              {column.customRender ? (
                                <div className="justify-self-center">{column.customRender(rawValue)}</div>
                              ) : column.format === TableDataType.IMAGE && rawValue ? (
                                <img className="w-14 h-10" src={rawValue} />
                              ) : column.format === TableDataType.FILE ? (
                                <div>{rawValue}</div>
                              ) : column.format === TableDataType.DATE ? (
                                <div className="tooltip" data-tip={formatDateTime(rawValue)}>
                                  {formatTableCell(rawValue, column.format)}
                                </div>
                              ) : column.link ? (
                                <Link to={column.link(rawValue)} className="underline">
                                  {formatTableCell(rawValue, column.format)}
                                </Link>
                              ) : column.format === TableDataType.DATES ? (
                                <div className="flex flex-col gap-1">
                                  {rawValue &&
                                    rawValue.split(",").map((item: any, index: number) => (
                                      <button
                                        className="w-28 btn btn-sm btn-secondary px-2 py-1"
                                        key={index}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {item}
                                      </button>
                                    ))}
                                </div>
                              ) : column.format === TableDataType.JSON && column.value === "gameSessions" ? (
                                formatTableGameSessions(rawValue, currency)
                              ) : column.format === TableDataType.DATE_PICKER ? (
                                <div className="relative">
                                  <button
                                    className="btn btn-circle btn-outline btn-sm"
                                    onClick={(e) => {
                                      e.stopPropagation() // prevent row click
                                      onDatePickerClick(row)
                                      setActiveDatePicker((prev) => (prev === row.id ? null : row.id))
                                    }}
                                  >
                                    <FaCalendarAlt />
                                  </button>
                                  {activeDatePicker === row.id && (
                                    <div
                                      className="absolute z-50 mt-2 -translate-x-1/3 bg-white shadow-md p-2 rounded-md w-32"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                      }}
                                    >
                                      <MultiDatesPicker
                                        id="schedule"
                                        title="Schedule"
                                        onChange={(field: string, dates: string[]) => handleDateChange?.(row.id, dates)}
                                        data={dateData}
                                      />
                                    </div>
                                  )}
                                </div>
                              ) : column.value === "gameSessionId" ? (
                                formatTableGameSessions(gameSessions, currency)
                              ) : (
                                formatTableCell(rawValue, column.format)
                              )}
                            </td>
                          )
                        })}
                      {actions && actions.length > 0 && (
                        <td>
                          <div className="flex space-x-3">
                            {actions.map(
                              ({ name, condition, handler, buttonColor }) =>
                                (condition ? condition(row) : true) && (
                                  <button
                                    className={`btn btn-sm ${buttonColor ? buttonColor : "btn-primary"} modal-button`}
                                    onClick={(e) => onAction(e, row, handler)}
                                    key={name}
                                  >
                                    {name}
                                  </button>
                                )
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <TablePagination page={page} totalPages={totalPages} onJump={onJump} />
        </div>
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default Table
