import MultiOptTimeRangePicker from "components/date/MultiOptTimePeriodPicker"
import { StatCard } from "components/stats/Card"
import { useProps } from "contexts/PropsContext"
import { useStats } from "hooks/useStats"
import { useUserCount } from "hooks/useUserCount"
import { FC, useCallback, useMemo, useState, ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { EnumTimePeriod } from "util/dashboard"
import DropdownMenu from "components/inputs/DropdownMenu"
import { tenantOptions } from "constants/bonus"

const Dashboard: FC = () => {
  const { user } = useProps()
  const { t } = useTranslation(["landing"])

  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])
  const [tenant, setTenant] = useState<string>("")

  const handleChangeTimeFrame = useCallback((dates: (Date | null)[]) => setTimeframe(dates), [])
  const { loading: loadingUserCount, data: userCount } = useUserCount(timeframe, tenant)
  const { loading: loadingStats, data: stats } = useStats(timeframe, tenant)
  const { kycFilesCount } = useProps()

  const ggr = useMemo(() => (stats?.wager ?? 0) - (stats?.win ?? 0), [stats])
  const ngr = useMemo(
    () =>
      (stats?.wager ?? 0) -
      (stats?.win ?? 0) -
      (stats?.bonus ?? 0) -
      (stats?.affiliate ?? 0) -
      (stats?.rakeback ?? 0) -
      (stats?.reward ?? 0) -
      (stats?.vipReward ?? 0) -
      (stats?.promo ?? 0),
    [stats]
  )

  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      <div className="flex items-center justify-center text-xl text-black dark:text-white">
        {t("welcome", { value: user?.display_name })}
      </div>

      <div className="flex max-w-2xl gap-4 flex-col md:flex-row md:gap-0 md:items-end md:mb-3">
        <MultiOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} defaultPeriod={EnumTimePeriod.today} />
        <DropdownMenu
          title="Tenant"
          options={tenantOptions}
          value={tenant}
          onChange={(ev: ChangeEvent<HTMLSelectElement>) => setTenant(ev.target.value)}
        />
      </div>

      <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-5">
        <StatCard title="ggr" bgColor="bg-yellow-500" value={ggr} loading={loadingStats} type="currency" />
        <StatCard title="ngr" bgColor="bg-yellow-600" value={ngr} loading={loadingStats} type="currency" />
        <StatCard title="user_count" value={userCount} loading={loadingUserCount} />
        <StatCard title="deposit_total" bgColor="bg-primary" value={stats?.deposit ?? 0} loading={loadingStats} type="currency" />
        <StatCard
          title="withdrawal_completed"
          bgColor="bg-secondary"
          value={stats?.withdrawal ?? 0}
          loading={loadingStats}
          type="currency"
        />
        <StatCard title="bets_total" bgColor="bg-orange-600" value={stats?.wager ?? 0} loading={loadingStats} type="currency" />
        <StatCard title="wins_total" bgColor="bg-blue-600" value={stats?.win ?? 0} loading={loadingStats} type="currency" />
        <StatCard title="Claimed Bonus" bgColor="bg-lime-600" value={stats?.bonus ?? 0} loading={loadingStats} type="currency" />
        <StatCard title="rakeback_claimed" bgColor="bg-gray-500" value={stats?.rakeback ?? 0} loading={loadingStats} type="currency" />
        <StatCard title="affiliate_claimed" bgColor="bg-info" value={stats?.affiliate ?? 0} loading={loadingStats} type="currency" />
        <StatCard title="promo_claimed" bgColor="bg-success-content" value={stats?.promo ?? 0} loading={loadingStats} type="currency" />

        <StatCard title="active_users" bgColor="bg-orange-300" value={stats?.activeUsers ?? 0} loading={loadingStats} />
        <StatCard title="ftd" bgColor="bg-gray-700" value={stats?.depositers ?? 0} loading={loadingStats} />
        <StatCard title="kyc_pending_counts" bgColor="bg-blue-400" value={kycFilesCount ?? 0} loading={loadingStats} />
      </div>
    </div>
  )
}

export default Dashboard
