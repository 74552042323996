import { useState, useEffect, FC } from "react"
import DropdownMenu from "components/inputs/DropdownMenu"
import { getTimePeriod, TimePeriodOptions, EnumTimePeriod } from "util/dashboard"
import TimeRangePicker from "./TimeRangePicker"

interface IProps {
  defaultPeriod: EnumTimePeriod
  onTimeframeChange: (timeframe: Date[]) => void
}

const MultiOptTimeRangePicker: FC<IProps> = ({ defaultPeriod, onTimeframeChange }) => {
  const [timePeriod, setTimePeriod] = useState(defaultPeriod)
  const [timeframe, setTimeframe] = useState<(Date | null)[]>(getTimePeriod(timePeriod))

  const handleSelect = (e: any) => {
    const selectedPeriod = e.target.value as EnumTimePeriod
    setTimePeriod(selectedPeriod)

    if (selectedPeriod === EnumTimePeriod.custom) {
      setTimeframe([null, null])
      return
    }
    setTimeframe(getTimePeriod(selectedPeriod))
  }

  useEffect(() => {
    if (timeframe.filter(Boolean).length === 2) {
      onTimeframeChange(timeframe as Date[])
    }
  }, [onTimeframeChange, timeframe])

  return (
    <div className="flex gap-2 sm:flex-row flex-col">
      <div className="w-full sm:w-44">
        <DropdownMenu options={TimePeriodOptions} value={timePeriod} onChange={handleSelect} />
      </div>
      <TimeRangePicker
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        onTimeframeChange={onTimeframeChange}
        disabled={timePeriod !== EnumTimePeriod.custom}
      />
    </div>
  )
}

export default MultiOptTimeRangePicker
