import { REQ } from "constants/api/requests"
import { IResponse } from "constants/interfaces"
import useApi from "util/api"

export const PaymentServiceAdmin = {
  async getCurRates(currency: string): Promise<{ rates: Record<string, number> } | null> {
    const params = { currency }

    try {
      const { data } = await useApi.get<IResponse<{ rates: Record<string, number> }>>(REQ.ADMIN_DASHBOARD.PAYMENTS.CUR_EXCHANGE_RATES, {
        params
      })

      if (data?.success) {
        return data.response
      }
    } catch (error: any) {
      console.error("Failed to fetch currency rates", error)
    }

    return null
  }
}
