import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { UserRole } from "services/user/type"

import { URL } from "../../constants/api/requests"
import { useProps } from "../../contexts/PropsContext"

interface IProps {
  open: boolean
  setOpen: (op: boolean) => void
  children?: any
}

const NavSide = (props: IProps) => {
  const { open, children, setOpen } = props
  const { authenticated, kycFilesCount, user, submittedWithdrawalsCount } = useProps()
  const { t } = useTranslation("landing", { useSuspense: false })

  return (
    <div className="flex h-auto min-h-[calc(100vh-64px)]">
      <div className={`!max-h-full bg-base-300 lg:block ${open ? "block" : "hidden"}`}>
        <ul className="p-4 space-y-2 overflow-y-auto menu w-60 bg-base-300 text-base-content">
          {authenticated && (
            <>
              {user.role === UserRole.ADMIN ? (
                <>
                  <li onClick={() => setOpen(false)}>
                    <Link to={URL.DASHBOARD} className="flex justify-start text-black text-16 dark:text-white">
                      {t("dashboard")}
                    </Link>
                  </li>
                  <li onClick={() => setOpen(false)}>
                    <Link to={URL.CMS} className="flex justify-start text-black text-16 dark:text-white">
                      {t("cms")}
                    </Link>
                  </li>
                </>
              ) : null}

              <li onClick={() => setOpen(false)}>
                <Link to={URL.USERS} className="flex justify-start text-black text-16 dark:text-white">
                  {t("users")}
                </Link>
              </li>
              <li onClick={() => setOpen(false)}>
                <Link to={URL.BONUSES} className="flex justify-start text-black text-16 dark:text-white">
                  Bonus
                </Link>
              </li>
              <li onClick={() => setOpen(false)}>
                <Link to={URL.TRANSACTIONS} className="flex justify-start text-black text-16 dark:text-white">
                  {t("transactions")}
                </Link>
              </li>
              {user.role === UserRole.ADMIN || user.role === UserRole.SUPPORT ? (
                <li onClick={() => setOpen(false)}>
                  <Link to={URL.BANK_TRANSFER} className="flex justify-start text-black text-16 dark:text-white">
                    Withdrawals
                    {submittedWithdrawalsCount ? (
                      <div className="bg-[#d33737] px-3 py-1 absolute right-0 rounded-full">{submittedWithdrawalsCount}</div>
                    ) : null}
                  </Link>
                </li>
              ) : null}
              <li onClick={() => setOpen(false)}>
                <Link to={URL.PROMO_CODES} className="flex justify-start text-black text-16 dark:text-white">
                  {t("promo_codes")}
                </Link>
              </li>
              {user.role === UserRole.ADMIN ? (
                <li onClick={() => setOpen(false)}>
                  <Link to={URL.ACTIVITY} className="flex justify-start text-black text-16 dark:text-white">
                    {t("activity")}
                  </Link>
                </li>
              ) : null}
              <li onClick={() => setOpen(false)}>
                <Link to={URL.KYC_FILES} className="flex justify-start text-black text-16 dark:text-white relative">
                  KYC Files
                  {kycFilesCount ? <div className="bg-[#d33737] px-3 py-1 absolute right-0 rounded-full">{kycFilesCount}</div> : null}
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>

      <div className="w-full overflow-x-scroll">{children}</div>
    </div>
  )
}

export default NavSide
