import clsx from "clsx"
import SingleOptTimeRangePicker from "components/date/SingleOptTimePeriodPicker"
import DropdownMenu from "components/inputs/DropdownMenu"
import { ModalDeclineAction } from "components/modals/ModalDeclineAction"
import { tenantOptions } from "constants/bonus"
import { tableBankTransfersColumns } from "constants/transactions"
import { useProps } from "contexts/PropsContext"
import { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import transactionService from "services/transaction/transaction.service"
import { Coin, UserRole } from "services/user/type"

import ModalConfirmAction from "../components/modals/ModalConfirmAction"
import Table from "../components/tables/Table"
import { URL } from "../constants/api/requests"
import { formatFiatCurrency } from "../util/formatter"

enum Tabs {
  SUBMITTED = "SUBMITTED",
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED"
}

export const BankTransfers = () => {
  const navigate = useNavigate()
  const { onSubmittedWithdrawalsCount, user } = useProps()
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.SUBMITTED)
  const { t }: { t: any } = useTranslation(["landing"], { useSuspense: false })
  const [declineRow, setDeclineRow] = useState<any>(null)
  const [approveRow, setApproveRow] = useState<any>(null)
  const [tableUpdatedAt, setTableUpdatedAt] = useState<Date | number>(Date.now())
  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])
  const [tenant, setTenant] = useState<string>("")

  const handleChangeTimeFrame = (dates: (Date | null)[]) => setTimeframe(dates)

  const onOpenApproveRow = (row: any): void => {
    setApproveRow(row)
  }

  const approveRowHandler = (): void => {
    setApproveRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onApproveTransaction = async (tx: any): Promise<void> => {
    if (!tx?.id) return

    const reference = tx.other.referenceId as string
    const isPayAdmit = reference?.startsWith("payadmit_")

    const isSubmitted = tx.status === "SUBMITTED"

    try {
      if (isPayAdmit) {
        if (isSubmitted) {
          const response = await transactionService.approveSubmittedPayadmitTransaction(tx.id, tx.status)
          if (response) toast.success(t("success_action_transaction", { actionText: "Approved" }))
        } else {
          const response = await transactionService.approvePayadmitTransaction(tx.id, tx.status)
          if (response) toast.success(t("success_action_transaction", { actionText: "Approved" }))
        }
      } else {
        if (isSubmitted) {
          const response = await transactionService.approveSubmittedCryptoTransaction(tx.id, tx.status)
          if (response) toast.success(t("success_action_transaction", { actionText: "Approved" }))
        } else {
          const response = await transactionService.approveCryptoTransaction(tx.id, tx.status)
          if (response) toast.success(t("success_action_transaction", { actionText: "Approved" }))
        }
      }
    } catch (err: any) {
      toast.error(err?.message || "Failed")
    }

    onSubmittedWithdrawalsCount()
  }

  const onOpenDeclineRow = (row: any): void => {
    setDeclineRow(row)
  }

  const declineRowHandler = (): void => {
    setDeclineRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onDeclineBankTransfer = async (tx: any, reason: string): Promise<void> => {
    const reference = tx.other.referenceId as string
    const isPayAdmit = reference?.startsWith("payadmit_")

    const isSubmitted = tx.status === "SUBMITTED"

    if (isPayAdmit) {
      if (isSubmitted) {
        const response = await transactionService.declineSubmittedPayadmitTransaction(tx.id, reason, tx.status)
        if (response) toast.success(t("success_action_transaction", { actionText: t("declined") }))
      } else {
        const response = await transactionService.declinePayadmitTransaction(tx.id, reason, tx.status)
        if (response) toast.success(t("success_action_transaction", { actionText: t("declined") }))
      }
    } else {
      if (isSubmitted) {
        const response = await transactionService.declineSubmittedCryptoTransaction(tx.id, reason, tx.status)
        if (response) toast.success(t("success_action_transaction", { actionText: t("declined") }))
      } else {
        const response = await transactionService.declineCryptoTransaction(tx.id, reason, tx.status)
        if (response) toast.success(t("success_action_transaction", { actionText: t("declined") }))
      }
    }

    onSubmittedWithdrawalsCount()
  }

  const actions = [
    {
      name: t("decline"),
      condition: (row: any) =>
        [Tabs.SUBMITTED, Tabs.PENDING].includes(row?.status) && row?.type === "WITHDRAWAL" && user.role === UserRole.ADMIN,
      handler: onOpenDeclineRow,
      buttonColor: "btn-secondary"
    },
    {
      name: "Approve",
      condition: (row: any) =>
        [Tabs.SUBMITTED, Tabs.PENDING].includes(row?.status) && row?.type === "WITHDRAWAL" && user.role === UserRole.ADMIN,
      handler: onOpenApproveRow
    }
  ]

  const onRowClick = (row: any): void => {
    if (row?.user_id) {
      const url = URL.USER.replace(":id", row.user_id)
      window.open(url, "_blank")
    }
  }

  return (
    <div className="w-full p-8">
      <div className="w-full grid grid-col-1 gap-8 px-8">
        <div className="flex items-center justify-center text-xl text-black dark:text-white">Withdrawals</div>

        <div className="flex items-center justify-center">
          <div className="tabs">
            {Object.keys(Tabs).map((tab) => (
              <a
                key={`tab-${tab}`}
                onClick={() => setActiveTab(tab as Tabs)}
                className={clsx("tab tab-bordered", {
                  "tab-active": tab === activeTab
                })}
              >
                {tab}
              </a>
            ))}
          </div>
        </div>

        <div className="flex flex-row gap-8 max-w-lg">
          <SingleOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} />
          <DropdownMenu
            title="Tenant"
            options={tenantOptions}
            value={tenant}
            onChange={(ev: ChangeEvent<HTMLSelectElement>) => setTenant(ev.target.value)}
          />
        </div>

        <Table
          title={`${activeTab} Withdrawals`}
          handler={transactionService.getTransactionsTable}
          columns={tableBankTransfersColumns}
          actions={actions}
          updatedAt={tableUpdatedAt}
          filters={{
            type: "WITHDRAWAL",
            status: activeTab,
            coin: Coin.USD,
            createdAt: timeframe
          }}
          onRowClick={onRowClick}
          isExport={activeTab === Tabs.COMPLETED}
        />

        <ModalConfirmAction
          data={approveRow}
          handler={approveRowHandler}
          text={t("modal_conformation_transaction", {
            actionText: "approve",
            usd_amount: formatFiatCurrency(approveRow?.usd_amount),
            user: approveRow?.user
          })}
          actionText="Approve"
          actionHandler={onApproveTransaction}
        />
        <ModalDeclineAction
          data={declineRow}
          handler={declineRowHandler}
          text={t("modal_conformation_transaction", {
            actionText: "decline",
            usd_amount: formatFiatCurrency(declineRow?.usd_amount),
            user: declineRow?.user
          })}
          actionText={t("decline")}
          actionHandler={onDeclineBankTransfer}
          isFullData={true}
        />
      </div>
    </div>
  )
}
