import { getData as getCountryData } from "country-list"
import { getAllTimezones } from "countries-and-timezones"
import { TableDataType } from "./eums"
import { TableColumnsInterface } from "./interfaces"

export const timezoneOptions = Object.keys(getAllTimezones()).map((timezone) => ({ value: timezone, name: timezone }))

export const tablePageSize = [5, 10, 20, 50, 100, 200]
export const countryInfoList = getCountryData()
  .map((countryInfo) => ({ value: countryInfo.code, name: countryInfo.name }))
  .sort((a, b) => a.name.localeCompare(b.name))

export const tableUsersColumns: TableColumnsInterface[] = [
  {
    value: "created_at",
    title: "Created At",
    format: TableDataType.DATE,
    sorting: true
  },
  {
    value: "tenant_id",
    title: "Tenant",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "personal_profile.first_name",
    title: "First Name",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "personal_profile.last_name",
    title: "Last Name",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "username",
    title: "Username",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "activated",
    title: "Activated",
    format: TableDataType.BOOLEAN,
    sorting: true
  },
  {
    value: "email_address",
    title: "Email Address",
    format: TableDataType.SHORTENED_STRING,
    sorting: true
  },
  {
    value: "email_verified",
    title: "Email Verified",
    format: TableDataType.BOOLEAN,
    sorting: true
  },
  {
    value: "total_deposit_amount",
    title: "Total Deposit Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "balance",
    title: "Total Balance",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "level",
    title: "VIP level",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "personal_profile.country",
    title: "Country",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "phone_number",
    title: "Phone Number",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "site_lang",
    title: "Language",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "personal_profile.timezone",
    title: "Timezone",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "wager",
    title: "Wager",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "id",
    title: "Id",
    format: TableDataType.SHORTENED_STRING,
    // link: (id: string) => `/user/${id}`,
    sorting: true
  },
  {
    value: "gameSessions",
    title: "Sessions",
    format: TableDataType.JSON,
    sorting: false
  }
]

export const tableUserBets: TableColumnsInterface[] = [
  {
    value: "id",
    title: "Id",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "gameSessionId",
    title: "Session ID",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "type",
    title: "Bet Type",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "sportsbet_status",
    title: "Sportsbet status",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "game_name",
    title: "Game Name",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "fiat_amount",
    title: "Fiat Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "crypto_amount",
    title: "Crypto Amount",
    format: TableDataType.CRYPTO_CURRENCY,
    sorting: true
  },
  {
    value: "win_fiat_amount",
    title: "Fiat Amount Won",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "win_crypto_amount",
    title: "Crypto Amount Won",
    format: TableDataType.CRYPTO_CURRENCY,
    sorting: true
  },
  {
    value: "coin",
    title: "Coin",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "user_fiat_balance",
    title: "User Balance USD",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "finished",
    title: "Finished",
    format: TableDataType.BOOLEAN,
    sorting: true
  },
  {
    value: "rollbacked",
    title: "Rollbacked",
    format: TableDataType.BOOLEAN,
    sorting: true
  },
  {
    value: "created_at",
    title: "Created At",
    format: TableDataType.DATE,
    sorting: true
  }
]

export const tableUserTransactions: TableColumnsInterface[] = [
  {
    value: "id",
    title: "Id",
    format: TableDataType.STRING,
    sorting: true
  },
  // {
  //   value: "tx_id",
  //   title: "Tx Id",
  //   format: TableDataType.STRING,
  //   sorting: false
  // },
  {
    value: "coin",
    title: "Coin",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "amount",
    title: "Amount",
    format: TableDataType.CRYPTO_CURRENCY,
    sorting: true
  },
  {
    value: "usd_amount",
    title: "USD Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "cardNumber",
    title: "Card Number",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "status",
    title: "Status",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "type",
    title: "Type",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "created_at",
    title: "Created At",
    format: TableDataType.DATE,
    sorting: true
  },
  {
    value: "updated_at",
    title: "Updated At",
    format: TableDataType.DATE,
    sorting: true
  }
]

export const tableUserReferrals: TableColumnsInterface[] = [
  {
    value: "user_id",
    title: "User Id",
    format: TableDataType.NUMBER,
    sorting: false
  },
  {
    value: "activated",
    title: "Activated",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "id",
    title: "Id",
    format: TableDataType.NUMBER,
    sorting: false
  },
  {
    value: "display_name",
    title: "Display Name",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "username",
    title: "Username",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "email_address",
    title: "Email Address",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "email_verified",
    title: "Email Verified",
    format: TableDataType.BOOLEAN,
    sorting: true
  },
  {
    value: "role",
    title: "Role",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "rakeback_earned",
    title: "Rakeback Earned",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "rakeback_balance",
    title: "Rakeback Balance",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "created_at",
    title: "Created At",
    format: TableDataType.DATE,
    sorting: true
  },
  {
    value: "phone_number",
    title: "Phone Number",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "phone_number_verified",
    title: "Phone Number Verified",
    format: TableDataType.BOOLEAN,
    sorting: true
  }
]

export const trustedStatuses = [
  {
    value: "TRUSTED",
    name: "TRUSTED"
  },
  {
    value: "UNTRUSTED",
    name: "UNTRUSTED"
  },
  {
    value: "INVALID",
    name: "INVALID"
  },
  {
    value: "NOT_STARTED",
    name: "NOT STARTED"
  }
]

export const roles = [
  {
    value: "ADMIN",
    name: "ADMIN"
  },
  // {
  //   value: "MOD",
  //   name: "MOD"
  // },
  {
    value: "SUPPORT",
    name: "SUPPORT"
  },
  // {
  //   value: "INFLUENCER",
  //   name: "INFLUENCER"
  // },
  // {
  //   value: "VIP",
  //   name: "VIP"
  // },
  {
    value: "USER",
    name: "USER"
  }
]

export const Genders = [
  {
    value: "Male",
    name: "Male"
  },
  {
    value: "Female",
    name: "Female"
  }
]

export const coins = [
  {
    value: "BTC",
    name: "BTC"
  },
  {
    value: "ETH",
    name: "ETH"
  },
  {
    value: "LTC",
    name: "LTC"
  }
]

export const transactionStatuses = [
  {
    value: "PENDING",
    name: "PENDING"
  },
  {
    value: "COMPLETED",
    name: "COMPLETED"
  },
  {
    value: "CONFIRMED",
    name: "CONFIRMED"
  },
  {
    value: "UNCONFIRMED",
    name: "UNCONFIRMED"
  },
  {
    value: "DECLINED",
    name: "DECLINED"
  },
  {
    value: "FAILED",
    name: "FAILED"
  },
  {
    value: "REFUNDED",
    name: "REFUNDED"
  }
]

export const transactionTypes = [
  {
    value: "DEPOSIT",
    name: "DEPOSIT"
  },
  {
    value: "WITHDRAWAL",
    name: "WITHDRAWAL"
  },
  {
    value: "TRANSFER",
    name: "TRANSFER"
  },
  {
    value: "REFUND",
    name: "REFUND"
  },
  {
    value: "BET_ROLLBACK",
    name: "BET ROLLBACK"
  },
  {
    value: "WIN_ROLLBACK",
    name: "WIN ROLLBACK"
  },
  {
    value: "RAKEBACK",
    name: "RAKEBACK"
  },
  {
    value: "RAKEBACK_ROLLBACK",
    name: "RAKEBACK ROLLBACK"
  },
  {
    value: "RAKEBACK_CLAIM",
    name: "RAKEBACK CLAIM"
  },
  {
    value: "AFFILIATE_PAY",
    name: "AFFILIATE PAY"
  },
  {
    value: "AFFILIATE_ROLLBACK",
    name: "AFFILIATE ROLLBACK"
  },
  {
    value: "AFFILIATE_CLAIM",
    name: "AFFILIATE CLAIM"
  },
  {
    value: "RAKE",
    name: "RAKE"
  },
  {
    value: "BET",
    name: "BET"
  },
  {
    value: "WIN",
    name: "WIN"
  },
  {
    value: "BONUS_CLAIM",
    name: "BONUS CLAIM"
  },
  {
    value: "BONUS_EXPIRE",
    name: "BONUS EXPIRE"
  },
  {
    value: "BONUS_CANCEL",
    name: "BONUS CANCEL"
  },
  {
    value: "BONUS_TRANSFER",
    name: "BONUS TRANSFER"
  },
  {
    value: "CLAIM_DAILY_REWARD",
    name: "DAILY REWARD"
  },
  {
    value: "CLAIM_WEEKLY_REWARD",
    name: "WEEKLY REWARD"
  },
  {
    value: "CLAIM_MONTHLY_REWARD",
    name: "MONTHLY REWARD"
  },
  {
    value: "VIP_REWARD",
    name: "VIP REWARD"
  },
  {
    value: "OTHER",
    name: "OTHER"
  }
]

export const tableUserRewardGames: TableColumnsInterface[] = [
  {
    value: "id",
    title: "Id",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "user_id",
    title: "User Id",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "title",
    title: "Game Name",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "game_identifier",
    title: "Game Identifier",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "issue_id",
    title: "Issue Id",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "status",
    title: "Status",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "used",
    title: "Used",
    format: TableDataType.BOOLEAN,
    sorting: true
  },
  {
    value: "win_fiat_amount",
    title: "win_fiat_amount",
    format: TableDataType.NUMBER,
    sorting: true
  }
]

export const formatTransactionTypes = () => {
  return transactionTypes.reduce((obj, item) => Object.assign(obj, { [item.value]: item.name }), {})
}

export const tableBonusesColumns: TableColumnsInterface[] = [
  {
    value: "id",
    title: "Id",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "title",
    title: "Title",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "banner.banner_image",
    title: "Banner",
    format: TableDataType.IMAGE,
    sorting: false
  },
  {
    value: "tenant_id",
    title: "Tenant",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "active",
    title: "Active",
    format: TableDataType.BOOLEAN,
    sorting: true
  },
  {
    value: "bonus_percent",
    title: "Bonus Percent",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "min_deposit",
    title: "Min Deposit Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "max_bonus_amount",
    title: "Max Bonus Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "max_usage_per_user",
    title: "Max Usage Per User",
    format: TableDataType.NUMBER,
    sorting: false
  },
  {
    value: "days_to_expire",
    title: "Days to Expire",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "wager_requirements",
    title: "Wager Reqruirements",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "schedule",
    title: "Schedule",
    format: TableDataType.DATE_PICKER,
    sorting: true
  }
]

export const tableComments: TableColumnsInterface[] = [
  {
    value: "commentor",
    title: "Commentor",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "created_at",
    title: "Created",
    format: TableDataType.DATE,
    sorting: true
  },
  {
    value: "comment",
    title: "Comment",
    format: TableDataType.STRING,
    sorting: true
  }
]
