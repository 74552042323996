import dayjs from "libs/dayjs"
import { GameStatusType, TableDataType } from "../constants/eums"
import { toast } from "react-toastify"
import copyIcon from "../assets/icons/copy-icon.svg"

export const formatDate = (date: string | Date) => {
  return dayjs(date).format("DD.MM.YYYY")
}
export const formatDateTime = (date: string | Date) => {
  return dayjs(date).format("DD.MM.YYYY h:mm:ss A Z")
}

export const formatBoolean = (value?: boolean) => {
  return value ? "YES" : "NO"
}

export const formatFiatCurrency = (value?: number | string, placeholder = "") => {
  if (!value) return placeholder

  let amount = Number(value)
  // if (amount < 0) amount *= -1

  return `$${(amount / 100).toFixed(2)}`
}

export const formatCryptoCurrency = (value?: number | string) => {
  if (!value) return ""

  let amount = Number(value)
  // if (amount < 0) amount *= -1

  return (amount / 1e8).toString()
}

export const formatPercentage = (value?: number | string) => {
  if (!value) return ""
  return `${value} %`
}

export const formatTableCell = (value: any, type: TableDataType) => {
  switch (type) {
    case TableDataType.DATE:
      return formatDate(value)
    case TableDataType.BOOLEAN:
      return formatBoolean(value)
    case TableDataType.FIAT_CURRENCY:
      return formatFiatCurrency(value)
    case TableDataType.TFIAT_CURRENCY:
      return formatFiatCurrency(value, "-")
    case TableDataType.CRYPTO_CURRENCY:
      return formatCryptoCurrency(value)
    case TableDataType.PERCENTAGE:
      return formatPercentage(value)
    case TableDataType.SHORTENED_STRING:
      return (
        <button
          className="text-blue-500 flex gap-2 underline hover:text-blue-700"
          onClick={(e) => {
            e.stopPropagation() // Prevent row click
            navigator.clipboard.writeText(value)
            toast.success("Copied to clipboard!")
          }}
          title="Click to copy full value"
        >
          {shortenString(value)}
          <img src={copyIcon} alt="Copy Icon" />
        </button>
      )

    default:
      return value
  }
}

export const shortenString = (value: string, startLength: number = 6, endLength: number = 4) => {
  if (!value) return ""
  return value.length > startLength + endLength ? `${value.slice(0, startLength)}...${value.slice(-endLength)}` : value
}

export function formatRewardGameStatus(rewardGame: any): GameStatusType {
  if (rewardGame?.issue_id) {
    switch (rewardGame?.status) {
      case "active":
        return GameStatusType.Play
      case null:
        return GameStatusType.Pending
      default:
        return GameStatusType.Claimed
    }
  }

  return GameStatusType.Claim
}

export const formatDateRange = (fromDate?: Date | null, untilDate?: Date | null) => {
  let formattedRange = ""
  if (fromDate) {
    if (fromDate.getTime() === 0) {
      formattedRange += "- "
    } else {
      formattedRange += `${formatDate(fromDate)} - `
    }
  }
  if (untilDate) {
    if (untilDate.getTime() >= 0) {
      formattedRange += `${formatDate(untilDate)}`
    }
  }
  return formattedRange
}

export const formatWordToCapitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
