import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import totalDataService from "services/total/total.service"

export const useUserCount = (timeframe: (Date | null)[], tenant: string) => {
  const [loading, setLoading] = useState(false)
  const [userCount, setUserCount] = useState(0)

  useEffect(() => {
    const getUserCountValue = async () => {
      try {
        setLoading(true)
        const res = await totalDataService.getUserCount(timeframe)
        if (res?.data) {
          setUserCount(res.data)
        }
      } catch (err) {
        toast.error("Get User Count Failed")
      } finally {
        setLoading(false)
      }
    }

    if (timeframe.filter(Boolean).length !== 0) {
      getUserCountValue()
    }
  }, [timeframe, tenant])

  return { loading, data: userCount }
}
